import styled from '@emotion/styled'
import {
  flex,
  flexbox,
  space,
  layout,
  height,
  border,
  boxShadow,
} from 'styled-system'
import type {
  FlexProps,
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  HeightProps,
  BorderProps,
  BoxShadowProps,
} from 'styled-system'

type ColumnProps = FlexProps &
  FlexboxProps &
  SpaceProps &
  LayoutProps &
  HeightProps &
  BorderProps &
  BoxShadowProps

const Column = styled('div')<ColumnProps>(
  flex,
  flexbox,
  space,
  layout,
  height,
  border,
  boxShadow
)

Column.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
}

export default Column
