interface Page {
  label: string
  path: string
}

const PRICING_PAGE: Page = {
  label: 'Pricing',
  path: '/',
}

const ABOUT_PAGE: Page = {
  label: 'About',
  path: '/about',
}

export const PAGES: Page[] = [PRICING_PAGE, ABOUT_PAGE]
