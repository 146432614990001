import styled from '@emotion/styled'
import {
  flex,
  flexWrap,
  flexbox,
  gridGap,
  space,
  layout,
  color,
  border,
  borderBottom,
  borderTop,
  position,
  padding,
} from 'styled-system'
import type {
  FlexProps,
  FlexWrapProps,
  FlexboxProps,
  GridGapProps,
  SpaceProps,
  LayoutProps,
  ColorProps,
  BorderProps,
  BorderBottomProps,
  BorderTopProps,
  PositionProps,
  PaddingProps,
} from 'styled-system'

type RowType = FlexProps &
  FlexboxProps &
  FlexWrapProps &
  GridGapProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  BorderProps &
  BorderBottomProps &
  BorderTopProps &
  PositionProps &
  PaddingProps

const Row = styled('div')<RowType>(
  flex,
  flexbox,
  flexWrap,
  gridGap,
  space,
  layout,
  color,
  border,
  borderBottom,
  borderTop,
  position,
  padding
)

Row.defaultProps = {
  display: 'flex',
  flexDirection: 'row',
}

export default Row
