import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const StyledLogo = styled.div`
  background-color: white;
  height: 100px;
  width: 100px;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid black;
  box-shadow: 0px 2px 10px 2px rgba(170, 171, 184, 0.4);
`

const Logo = () => {
  const logo = useStaticQuery(graphql`
    query MyQuery {
      file(relativePath: { eq: "throw.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <StyledLogo>
      <Img fluid={logo.file.childImageSharp.fluid} alt='judo throw' />
    </StyledLogo>
  )
}

export default Logo
