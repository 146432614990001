import React, { useRef, useEffect, LegacyRef, MouseEventHandler } from 'react'
import { Link } from 'gatsby'
import { CloseIcon } from '@chakra-ui/icons'

import { COLORS } from '../../assets/theme'
import Column from '../column'
import { PAGES } from './constants'
import Text from '../text'
import { useNavWindow } from '../../context/navContext'

const isElement = (menuRef: unknown): menuRef is HTMLElement => {
  return !!(menuRef as HTMLElement)?.contains
}

const NavMenu = () => {
  const menuRef = useRef<HTMLDivElement>(null)
  const [, setIsNavOpen] = useNavWindow()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        isElement(menuRef.current) &&
        !menuRef.current?.contains(event.target as HTMLElement)
      ) {
        setIsNavOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div
      ref={menuRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        zIndex: 9,
      }}
    >
      <Column
        height='100%'
        width='100%'
        borderRight={`1px solid ${COLORS.white}`}
        alignContent='center'
        style={{
          backgroundColor: COLORS.background,
        }}
        paddingTop='28px'
        boxShadow='0px 2px 10px 4px rgba(170, 171, 184, 0.4);'
      >
        {PAGES.map(({ label, path }) => (
          <Link
            key={label}
            to={path}
            style={{ textAlign: 'center', color: 'white', margin: '5px' }}
            activeStyle={{ color: COLORS.secondary }}
            onClick={() => setIsNavOpen(false)}
          >
            <Text fontSize='4' textAlign='left'>
              {label}
            </Text>
          </Link>
        ))}
        <CloseIcon
          onClick={() => setIsNavOpen((prevState) => !prevState)}
          role='button'
          w='20'
          h='20'
          style={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            cursor: 'pointer',
          }}
        />
      </Column>
    </div>
  )
}

export default NavMenu
