import React, { ReactElement, ReactNode } from 'react'
import styled from '@emotion/styled'
import {
  flexbox,
  FlexboxProps,
  fontSize,
  FontSizeProps,
  color,
  ColorProps,
  lineHeight,
  LineHeightProps,
  padding,
  PaddingProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from 'styled-system'

enum TITLE_TYPES {
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'span',
}

type AsTitles = keyof typeof TITLE_TYPES

interface TitleProps {
  as: AsTitles
  children: ReactNode
}

const Title = ({
  as: AsComponent,
  children,
  marginX = 'unset',
  paddingY,
  paddingBottom,
  color: textColor,
  fontSize: titleFontSize,
  alignSelf,
  justifySelf,
  lineHeight: titleLineHeight,
  textAlign: titleTextAlign,
}: TitleProps &
  FlexboxProps &
  SpaceProps &
  PaddingProps &
  ColorProps &
  FontSizeProps &
  LineHeightProps &
  TextAlignProps): ReactElement<typeof AsComponent> => {
  const StyledTitle = styled(AsComponent)(
    flexbox,
    fontSize,
    lineHeight,
    space,
    color,
    textAlign,
    padding
  )
  return (
    <StyledTitle
      alignSelf={alignSelf}
      justifySelf={justifySelf}
      marginX={marginX}
      paddingY={paddingY}
      paddingBottom={paddingBottom}
      color={textColor}
      fontSize={titleFontSize}
      lineHeight={titleLineHeight}
      textAlign={titleTextAlign}
    >
      {children}
    </StyledTitle>
  )
}

Title.defaultProps = {
  as: 'h1',
}

export default Title
