import styled from '@emotion/styled'
import {
  fontSize,
  fontFamily,
  fontStyle,
  lineHeight,
  textAlign,
  space,
  color,
  ColorProps,
  SpaceProps,
  FontSizeProps,
  FontWeightProps,
  FontStyleProps,
  LineHeightProps,
  TextAlignProps,
} from 'styled-system'

type TextType = FontSizeProps &
  FontWeightProps &
  FontStyleProps &
  ColorProps &
  LineHeightProps &
  SpaceProps &
  TextAlignProps

const Text = styled.span<TextType>`
  ${fontSize};
  ${fontFamily};
  ${fontStyle};
  ${color};
  ${lineHeight};
  ${space};
  ${textAlign}
  white-space: normal;
`

export default Text
