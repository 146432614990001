export const COLORS = {
  black: '#000000',
  background: '#14213d',
  secondary: '#fca311',
  gray: '#e5e5e5',
  darkGray: '#c1cad6',
  darkerGray: '#aaabb8',
  white: '#FFFFFF',
  fontWhite: 'rgba(255, 255, 255, 0.9)',
}
