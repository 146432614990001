import React, { ReactElement, ReactNode, useEffect } from 'react'
import { HamburgerIcon } from '@chakra-ui/icons'

import { StyledMain } from './layout.styles'
import './layout.css'
import { COLORS } from '../../assets/theme'
import Column from '../column'
import Logo from '../logo'
import Row from '../row'
import Title from '../title'
import { useNavWindow } from '../../context/navContext'
import NavMenu from '../nav-menu'

interface LayoutProps {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps): ReactElement<typeof StyledMain> => {
  const [isOpen, setIsNavOpen] = useNavWindow()

  return (
    <StyledMain>
      <Row
        position='relative'
        justifyContent='center'
        backgroundColor={COLORS.background}
        borderBottom={`2px solid ${COLORS.darkerGray}`}
      >
        {isOpen ? <NavMenu /> : null}
        <Column alignItems='center' paddingTop='2'>
          <Logo />
          <Title
            as='h1'
            paddingTop='10px'
            color={COLORS.fontWhite}
            fontSize='2.2em'
            lineHeight='1.15'
            textAlign='center'
          >
            Private Brazilian Jiu-Jitsu
          </Title>
        </Column>
        <HamburgerIcon
          onClick={() => setIsNavOpen((prevState) => !prevState)}
          role='button'
          w='28'
          h='28'
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
          }}
        />
      </Row>
      {children}
    </StyledMain>
  )
}

export default Layout
